<template>
  <div>
    <h2>Application-specific Essays</h2>
    <el-button
      :type="isFilterAlreadyCreatedThirdAppEssays ? 'primary' : ''"
      @click="isFilterAlreadyCreatedThirdAppEssays = true"
    >
      {{ $t('essays.viewSelectedPrompt') }}
    </el-button>
    <el-button
      :type="isFilterAlreadyCreatedThirdAppEssays ? '' : 'primary'"
      @click="isFilterAlreadyCreatedThirdAppEssays = false"
    >
      {{ $t('essays.viewAllPrompts') }}
    </el-button>
    <hr />
    <el-alert
      v-if="!loadingEssays && thirdAppWithEssays.length === 0"
      type="info"
      show-icon
      :closable="false"
    >
      <template slot="title">
        請先到
        <router-link :to="getAddApplicationObject()">
          申請表清單
        </router-link>
        選擇要使用的申請表，這裡會出現那些申請表的作文
      </template>
    </el-alert>
    <el-table
      v-else
      :data="thirdAppEssayPrompts"
      :span-method="getMergeApplicationEssayRow"
    >
      <el-table-column
        width="160"
        label="Application Name"
        prop="thirdAppName"
      />
      <el-table-column width="300" label="Schools">
        <template slot-scope="scope">
          <div
            :key="school.id"
            v-for="school in scope.row.schools"
            class="schoolList"
          >
            <router-link
              style="display: block; padding-bottom: 4px"
              :to="{
                name: 'School',
                params: { schoolId: school.slug, type: 'overview' }
              }"
            >
              <span v-if="lang === 'EN-US'">{{
                school.name[langList[lang]]
              }}</span>
              <span v-else
                >{{ school.name[langList[lang]] }}&nbsp;({{
                  school.name["en"]
                }})</span
              >
            </router-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="100" label="Essay Number" prop="essay.part" />
      <el-table-column width="100" label="Essay Option" prop="essay.prompt_order" />
      <el-table-column label="Essay Prompt" prop="essay.prompt" />
      <el-table-column width="100" label="Word Count">
        <template slot-scope="scope">
          <template v-if="loadingWordCount">
            <i class="el-icon-loading" />
          </template>
          <template v-else>
            <span v-if="scope.row.essay.student_essay">
              {{ scope.row.essay.student_essay.word_count }}
            </span>
            <span v-else>0</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column width="120" :label="$t('essays.columns.progress')">
        <template slot-scope="scope">
          <template v-if="loadingWordCount">
            <i class="el-icon-loading" />
          </template>
          <template v-else>
            <template v-if="scope.row.essay.student_essay">
              <span
                v-if="scope.row.essay.student_essay.status === studentEssayStatuses.incompleted"
              >
                {{ $t("essays.notStarted") }}
              </span>
              <el-tooltip
                v-else-if="scope.row.essay.student_essay.status === studentEssayStatuses.completed"
                :content="scope.row.essay.student_essay.complete_date"
                placement="top"
                popper-class="tooltipColor"
              >
                <span class="finished">
                  {{ $t("essays.finished") }}
                </span>
              </el-tooltip>
              <span
                class="inProgress"
                v-else-if="scope.row.essay.student_essay.status === studentEssayStatuses.processing"
              >
                {{ $t("essays.inProgress") }}
              </span>
            </template>
            <span v-else>
              {{ $t("essays.notStarted") }}
            </span>
          </template>
        </template>
      </el-table-column>  
      <el-table-column width="120" label="Actions">
        <template slot-scope="scope">
          <div>
            <template v-if="scope.row.essay.student_essay">
              <el-tooltip
                v-if="scope.row.essay.student_essay.word_count > 0"
                :content="
                  scope.row.essay.student_essay.status ===
                    studentEssayStatuses.completed
                    ? $t('essays.markStatusAsIncomplete')
                    : $t('essays.markStatusAsCompleted')
                "
                placement="top"
                popper-class="tooltipColor"
              >
                <span
                  v-if="
                    scope.row.essay.student_essay.status ===
                      studentEssayStatuses.completed
                  "
                  key="completed"
                  class="action-icon"
                  @click="
                    $emit('cancelStatusAsIncompleted', {
                      studentEssay: scope.row.essay.student_essay,
                      callbackFn: initialData
                    })
                  "
                >
                  <i class="fas fa-times" />
                </span>
                <span
                  v-else
                  key="incompleted"
                  class="action-icon"
                  @click="
                    $emit('markStatusAsCompleted', {
                      studentEssay: scope.row.essay.student_essay,
                      callbackFn: initialData
                    })
                  "
                >
                  <i class="fas fa-check" />
                </span>
              </el-tooltip>
              <span class="action-icon">
                <a :href="scope.row.essay.student_essay.essay_url" target="blank">
                  <i class="fas fa-edit" />
                </a>
              </span>
              <span
                class="warning-icon"
                @click="
                  $emit('deleteEssayDocument', {
                    studentEssayId: scope.row.essay.id,
                    callbackFn: initialData
                  })
                "
              >
                <i class="fas fa-trash-alt" />
              </span>
            </template>
            <span
              v-else
              key="create"
              class="action-icon"
              @click="() => createApplicationEssayDocument(scope.row.essay.id)"
            >
              <i class="fas fa-plus" />
            </span>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Essay from "@/class/essay";
import essayApi from "@/apis/essay";
import profileApi from "@/apis/profile";
import collectSchoolApi from "@/apis/collectSchool";
import roleMixin from "@/mixins/role";
import { thirdAppTypeDict } from "@/class/ThirdApp";
import StudentEssayStatuses from "@/views/private/Counseling/EssayComponets/StudentEssayStatuses";

export default {
  props: {
    isEnrolledCounseling: {
      type: Boolean,
      default: false
    },
    studentId: {
      type: Number,
      default: null
    },
    studentUsername: {
      type: String,
      default: ""
    },
    sessionClass: {
      type: Object,
      default: () => ({
        course_session: {}
      })
    },
    filterThirdApplication: {
      type: String,
      default: null
    }
  },
  mixins: [roleMixin],
  data() {
    return {
      loadingEssays: true,
      loadingWordCount: true,
      isFilterAlreadyCreatedThirdAppEssays: true,
      waittingForCreateEssayId: null,
      thirdAppWithEssays: [],
      collectedCounselingSchools: [],
      essayDocType: null
    };
  },
  computed: {
    lang() {
      return this.$store.getters["user/langValue"];
    },
    langList() {
      return {
        "ZH-TW": "tw",
        "EN-US": "en",
        "ZH-CN": "cn"
      };
    },
    studentEssayStatuses() {
      return StudentEssayStatuses;
    },
    thirdAppEssayPrompts() {
      let essays = this.thirdAppWithEssays;

      if (this.isFilterAlreadyCreatedThirdAppEssays) {
        essays = this.thirdAppWithEssays.filter(({ essay }) => essay.student_essay);
      }

      let applicationEssayCountMap = {};
      essays.forEach((essay) => {
        if (applicationEssayCountMap[essay.thirdAppName] === undefined) {
          applicationEssayCountMap[essay.thirdAppName] = 0;
        }
        applicationEssayCountMap[essay.thirdAppName] += 1;
      });

      const essaysWithRowAndCol = essays.map((essay) => {
        if (applicationEssayCountMap[essay.thirdAppName] === undefined) {
          return { ...essay, rowspan: 0, colspan: 0 };
        } else {
          const rowspan = applicationEssayCountMap[essay.thirdAppName];
          delete applicationEssayCountMap[essay.thirdAppName];
          return { ...essay, rowspan, colspan: 1 };
        }
      });
      return essaysWithRowAndCol;
    },
    thirdAppWithEssaysCreated() {
      return this.thirdAppWithEssays
        .filter(({ essay }) => essay.student_essay);
    }
  },
  watch: {
    studentId() {
      this.initialData();
    }
  },
  async created() {
    this.initialData();
  },
  methods: {
    async initialData() {
      let counselingStudentId = this.studentId;
      if (!counselingStudentId) return;
      await this.$store.dispatch("thirdApp/getUsingApplications", counselingStudentId);
      if (counselingStudentId) {
        const {
          essay_prompts: essayPrompts
        } = await essayApi.getThirdApplicationEssayPrompts(counselingStudentId);
        const collectSchools = await this.fetchCollectedCounselingSchools(counselingStudentId);
        await this.setThirdApplicationEssays(collectSchools, essayPrompts);
        await this.calculateEssaysWordCount();
        this.updateStudentEssaysWordCountAndStatus();
        this.essayDocType = await this.fetchUserEssayDocumentType();
      }
    },
    async fetchCollectedCounselingSchools(counselingStudentId) {
      const {
        counseling_schools
      } = await collectSchoolApi.fetchCollectedCounselingSchools({
        counselingId: 0,
        userId: counselingStudentId
      });
      return counseling_schools;
    },
    async setThirdApplicationEssays(collectSchools, essayPrompts) {
      let thirdAppMapSchools = {};
      collectSchools.forEach(({ application_portal, school }) => {
        if (application_portal) {
          if (!thirdAppMapSchools[application_portal]) {
            thirdAppMapSchools[application_portal] = [];
          }
          thirdAppMapSchools[application_portal].push(school);
        }
      });

      this.$store.getters["thirdApp/usingApplications"]
        .forEach((thirdApplication) => {
          thirdAppMapSchools[thirdApplication] = thirdAppMapSchools[thirdApplication] || [];
        });

      let thirdAppWithEssays = [];
      Object.keys(thirdAppMapSchools).forEach((thirdAppType) => {
        const essaysForThirdAppType = essayPrompts.filter((essayPrompt) => {
          switch(thirdAppType) {
            case "common_app":
              return essayPrompt.is_common_app;
            case "coalition_app":
              return essayPrompt.is_coalition_app;
            case "california_app":
              return essayPrompt.is_california_app;
          }
        });
        let essayNumber = 1;
        essaysForThirdAppType.forEach((essay) => {
          thirdAppWithEssays.push({
            essay,
            essayNumber,
            thirdAppType,
            thirdAppName: thirdAppTypeDict[thirdAppType],
            schools: thirdAppMapSchools[thirdAppType]
          });
          essayNumber += 1;
        });
      });

      if (this.filterThirdApplication) {
        this.thirdAppWithEssays = thirdAppWithEssays
          .filter(({ thirdAppType }) => thirdAppType === this.filterThirdApplication);
      } else {
        this.thirdAppWithEssays = [...thirdAppWithEssays];
      }
      this.loadingEssays = false;
    },
    async updateStudentEssaysWordCountAndStatus() {
      this.thirdAppWithEssays = this.thirdAppWithEssays
        .map(thirdAppWithEssay => {
          if (thirdAppWithEssay.essay.student_essay) {
            thirdAppWithEssay.essay.student_essay.status = Essay.getEssayStatus(
              thirdAppWithEssay.essay
            );
          }
          return { ...thirdAppWithEssay };
        });

      this.thirdAppWithEssaysCreated.forEach(thirdAppWithEssay => {
        essayApi.updateStudentEssay(thirdAppWithEssay.essay.student_essay.id, {
          word_count: thirdAppWithEssay.essay.student_essay.word_count,
          status: thirdAppWithEssay.essay.student_essay.status
        });
      });
    },
    async calculateEssaysWordCount() {
      let studentEssayIdMap = {};
      const thirdAppWithEssayPromises = this.thirdAppWithEssaysCreated
        .map(
          async (thirdAppWithEssay, index) => {
            studentEssayIdMap[index] = thirdAppWithEssay.essay.student_essay.id;
            return await essayApi.getThirdApplicationEssayPromptContent(
              thirdAppWithEssay.essay.student_essay.id
            );
          }
        );
      
      const essayWordCountMap = {};
      const thirdAppWithEssayDocs = await Promise.all(thirdAppWithEssayPromises);
      thirdAppWithEssayDocs.forEach(({ doc_content }, index) => {
        const wordCount = Essay.calculateEssayWordCount(doc_content);
        essayWordCountMap[studentEssayIdMap[index]] = wordCount;
      });
      this.thirdAppWithEssays = this.thirdAppWithEssays.map((thirdAppWithEssay) => {
        let wordCount = 0;
        if (thirdAppWithEssay.essay.student_essay) {
          wordCount = essayWordCountMap[thirdAppWithEssay.essay.student_essay.id];
          thirdAppWithEssay.essay.student_essay.word_count = wordCount;
        }
        return { ...thirdAppWithEssay };
      });
      this.loadingWordCount = false;
    },
    async fetchUserEssayDocumentType() {
      const { student: { essay_doc_type } } = await profileApi.getUserData(this.studentId);
      return essay_doc_type;
    },
    async updateStudentEssayDocumentType() {
      if (this.isRoleAdmin()) {
        await this.$store.dispatch(
          "profile/updateStudentProfileByAdmin",
          { id: this.studentId, newProfile: { essay_doc_type: this.essayDocType }}
        );
      } else if (this.$store.getters["user/getProfile"].id === this.studentId) {
        await this.$store.dispatch("profile/updateStudentProfile", {
          essay_doc_type: this.essayDocType
        });
      }
      if (this.waittingForCreateEssayId) {
        await this.createApplicationEssayDocument(this.waittingForCreateEssayId);
        this.waittingForCreateEssayId = null;
      }
    },
    async createApplicationEssayDocument(essayPromptId) {
      if (this.essayDocType === null) {
        this.essayDocType = await this.fetchUserEssayDocumentType();
      }
      if (this.essayDocType === null) {
        this.waittingForCreateEssayId = essayPromptId;
        const GOOGLE = 1;
        this.essayDocType = GOOGLE;
        this.updateStudentEssayDocumentType();
        return;
      }
      const { student_essay: { essay_url } } = await essayApi.createApplicationEssayDocument(
        this.essayDocType, this.studentId, essayPromptId
      );
      window.open(essay_url);
      this.initialData();
    },
    getMergeApplicationEssayRow({ row, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1) {
        return {
          rowspan: row.rowspan,
          colspan: row.colspan
        };
      }
    },
    getAddApplicationObject() {
      if (this.isEnrolledCounseling) {
        return {
          name: "counselingThirdApp",
          params: {
            id: this.sessionClass.id
          }
        };
      } else {
        return {
          name: "counselingUserApplication",
          params: {
            username: this.studentUsername
          }
        };
      }
    }
  }
};
</script>

<style scoped>
.schoolList:not(:first-child) {
  border-top: 1px solid #EBEEF5;
  padding-top: 4px;
  display: block;
}
</style>
