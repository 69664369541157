<template>
  <div>
    <h2>School-specific Essays</h2>
    <div class="viewSelectedPromptBtns">
      <el-button
        :type="isFilterAlreadyCreatedSchoolEssays ? 'primary' : ''"
        @click="isFilterAlreadyCreatedSchoolEssays = true"
      >
        {{ $t('essays.viewSelectedPrompt') }}
      </el-button>
      <el-button
        :type="isFilterAlreadyCreatedSchoolEssays ? '' : 'primary'"
        @click="isFilterAlreadyCreatedSchoolEssays = false"
      >
        {{ $t('essays.viewAllPrompts') }}
      </el-button>
    </div>
    <div>
      <el-button
        :type="isExpandAllSchools ? 'primary' : ''"
        @click="expandAllSchools"
      >
        Expand all
      </el-button>
      <el-button
        :type="isExpandAllSchools ? '' : 'primary'"
        @click="collapseAllSchools"
      >
        Collapse all
      </el-button>
    </div>
    <hr />
    <el-alert
      v-if="!loadingEssays && collectedSchoolWithEssays.length === 0"
      type="info"
      show-icon
      :closable="false"
    >
      <template slot="title">
        {{ $t("essays.pleaseAddCollegesFirst1") }}
        <router-link :to="getAddCollegeObject()">
          {{ $t("essays.pleaseAddCollegesFirst2") }}
        </router-link>
        {{ $t("essays.pleaseAddCollegesFirst3") }}
      </template>
    </el-alert>
    <div
      :key="school.name"
      v-for="(school, index) in collectedCounselingSchools"
    >
      <template v-if="getSchoolEssayPromptsByName(school.name).length > 0">
        <div @click="() => toggleSchoolOpenStatus(index)" class="panelTitle">
          <span v-if="school.opened" key="open">
            <i class="fas fa-chevron-down" />
          </span>
          <span v-else key="close">
            <i class="fas fa-chevron-right" />
          </span>
          <span>{{ school.school.name[langList[lang]] }}</span>
        </div>
        <div
          class="panelContent"
          v-if="school.opened"
        >
          <el-table :data="getSchoolEssayPromptsByName(school.name)">
            <el-table-column width="100" label="Essay Number" prop="essay.part"/>
            <el-table-column width="100" label="Essay Option" prop="essay.prompt_order" />
            <el-table-column label="Essay Prompt" prop="essay.prompt" />
            <el-table-column width="120" :label="$t('essays.columns.progress')">
              <template slot-scope="scope">
                <template v-if="loadingWordCount">
                  <i class="el-icon-loading" />
                </template>
                <template v-else>
                  <template v-if="scope.row.essay.student_essay">
                    <span
                      v-if="scope.row.essay.student_essay.status === studentEssayStatuses.incompleted"
                    >
                      {{ $t("essays.notStarted") }}
                    </span>
                    <el-tooltip
                      v-else-if="scope.row.essay.student_essay.status === studentEssayStatuses.completed"
                      :content="scope.row.essay.student_essay.complete_date"
                      placement="top"
                      popper-class="tooltipColor"
                    >
                      <span class="finished">
                        {{ $t("essays.finished") }}
                      </span>
                    </el-tooltip>
                    <span
                      class="inProgress"
                      v-else-if="scope.row.essay.student_essay.status === studentEssayStatuses.processing"
                    >
                      {{ $t("essays.inProgress") }}
                    </span>
                  </template>
                  <span v-else>
                    {{ $t("essays.notStarted") }}
                  </span>
                </template>
              </template>
            </el-table-column>
            <el-table-column width="100" label="Word Count">
              <template slot-scope="scope">
                <template v-if="loadingWordCount">
                  <i class="el-icon-loading" />
                </template>
                <template v-else>
                  <span v-if="scope.row.essay.student_essay">
                    {{ scope.row.essay.student_essay.word_count }}
                  </span>
                  <span v-else>0</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column width="120" label="Actions">
              <template slot-scope="scope">
                <template v-if="scope.row.essay.student_essay">
                  <el-tooltip
                    v-if="scope.row.essay.student_essay.word_count > 0"
                    :content="
                      scope.row.essay.student_essay.status ===
                        studentEssayStatuses.completed
                        ? $t('essays.markStatusAsIncomplete')
                        : $t('essays.markStatusAsCompleted')
                    "
                    placement="top"
                    popper-class="tooltipColor"
                  >
                    <span
                      v-if="
                        scope.row.essay.student_essay.status ===
                          studentEssayStatuses.completed
                      "
                      key="completed"
                      class="action-icon"
                      @click="
                        $emit('cancelStatusAsIncompleted', {
                          studentEssay: scope.row.essay.student_essay,
                          callbackFn: initialData
                        })
                      "
                    >
                      <i class="fas fa-times" />
                    </span>
                    <span
                      v-else
                      key="incompleted"
                      class="action-icon"
                      @click="
                        $emit('markStatusAsCompleted', {
                          studentEssay: scope.row.essay.student_essay,
                          callbackFn: initialData
                        })
                      "
                    >
                      <i class="fas fa-check" />
                    </span>
                  </el-tooltip>
                  <span class="action-icon">
                    <a :href="scope.row.essay.student_essay.essay_url" target="blank">
                      <i class="fas fa-edit" />
                    </a>
                  </span>
                  <span
                    class="warning-icon"
                    @click="
                      $emit('deleteEssayDocument', {
                        studentEssayId: scope.row.essay.id,
                        callbackFn: initialData
                      })
                    "
                  >
                    <i class="fas fa-trash-alt" />
                  </span>
                </template>
                <span
                  v-else
                  key="create"
                  class="action-icon"
                  @click="() => createApplicationEssayDocument(scope.row.essay.id)"
                >
                  <i class="fas fa-plus" />
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <hr />
      </template>
    </div>
  </div>
</template>

<script>
import Essay from "@/class/essay";
import essayApi from "@/apis/essay";
import profileApi from "@/apis/profile";
import collectSchoolApi from "@/apis/collectSchool";
import roleMixin from "@/mixins/role";
import StudentEssayStatuses from "@/views/private/Counseling/EssayComponets/StudentEssayStatuses";

export default {
  props: {
    isEnrolledCounseling: {
      type: Boolean,
      default: false
    },
    studentId: {
      type: Number,
      default: null
    },
    sessionClass: {
      type: Object,
      default: () => ({
        course_session: {}
      })
    },
    filterThirdApplication: {
      type: String,
      default: null
    }
  },
  mixins: [roleMixin],
  data() {
    return {
      loadingEssays: true,
      loadingWordCount: true,
      isFilterAlreadyCreatedSchoolEssays: true,
      waittingForCreateEssayId: null,
      collectedSchoolWithEssays: [],
      collectedCounselingSchools: [],
      essayDocType: null
    };
  },
  computed: {
    lang() {
      return this.$store.getters["user/langValue"];
    },
    langList() {
      return {
        "ZH-TW": "tw",
        "EN-US": "en",
        "ZH-CN": "cn"
      };
    },
    studentEssayStatuses() {
      return StudentEssayStatuses;
    },
    schoolsEssayPrompts() {
      let collectedSchoolWithEssays = [...this.collectedSchoolWithEssays];
      if (this.isFilterAlreadyCreatedSchoolEssays) {
        collectedSchoolWithEssays = this.collectedSchoolWithEssays.filter(({ essay }) => essay.student_essay);
      }
      return collectedSchoolWithEssays;
    },
    isExpandAllSchools() {
      return this.collectedCounselingSchools.every(
        collectedCounselingSchool => collectedCounselingSchool.opened
      );
    },
    collectedSchoolWithEssaysCreated() {
      return this.collectedSchoolWithEssays
        .filter(({ essay }) => essay.student_essay);
    }
  },
  watch: {
    studentId() {
      this.initialData();
    }
  },
  async created() {
    this.initialData();
  },
  methods: {
    async initialData() {
      let counselingStudentId = this.studentId;
      if (counselingStudentId) {
        const {
          essay_prompts: essayPrompts
        } = await essayApi.getThirdApplicationEssayPrompts(counselingStudentId);
        await this.fetchCollectedCounselingSchools(counselingStudentId, essayPrompts);
        await this.calculateEssaysWordCount();
        this.updateStudentEssaysWordCountAndStatus();
        this.essayDocType = await this.fetchUserEssayDocumentType();
      }
    },
    async fetchCollectedCounselingSchools(counselingStudentId, essayPrompts) {
      const {
        counseling_schools
      } = await collectSchoolApi.fetchCollectedCounselingSchools({
        counselingId: 0,
        userId: counselingStudentId
      });
      this.collectedCounselingSchools = counseling_schools.map(
        (counselingSchool) => ({ ...counselingSchool, opened: false })
      );
      const collectedSchoolsWithEssays = [];
      counseling_schools.forEach((counselingSchool) => {
        const schoolEssays = essayPrompts.filter(
          (essayPrompt) => essayPrompt.school_id === counselingSchool.school_id
        );
        schoolEssays.forEach((schoolEssay) => {
          collectedSchoolsWithEssays.push({
            ...counselingSchool,
            essay: schoolEssay
          });
        });
      });
      if (this.filterThirdApplication) {
        this.collectedSchoolWithEssays = collectedSchoolsWithEssays
          .filter(({ application_portal }) => application_portal === this.filterThirdApplication);
      } else {
        this.collectedSchoolWithEssays = [...collectedSchoolsWithEssays];
      }
    },
    async calculateEssaysWordCount() {
      let studentEssayIdMap = {};
      const collectedSchoolWithEssayPromises = this.collectedSchoolWithEssaysCreated
        .map(
          async (collectedSchoolWithEssay, index) => {
            studentEssayIdMap[index] = collectedSchoolWithEssay.essay.student_essay.id;
            return await essayApi.getThirdApplicationEssayPromptContent(
              collectedSchoolWithEssay.essay.student_essay.id
            );
          }
        );

      const essayWordCountMap = {};
      const collectedSchoolWithEssayDocs = await Promise.all(collectedSchoolWithEssayPromises);
      collectedSchoolWithEssayDocs.forEach(({ doc_content }, index) => {
        const wordCount = Essay.calculateEssayWordCount(doc_content);
        essayWordCountMap[studentEssayIdMap[index]] = wordCount;
      });

      this.collectedSchoolWithEssays = this.collectedSchoolWithEssays.map((collectedSchoolWithEssay) => {
        let wordCount = 0;
        if (collectedSchoolWithEssay.essay.student_essay) {
          wordCount = essayWordCountMap[collectedSchoolWithEssay.essay.student_essay.id];
          collectedSchoolWithEssay.essay.student_essay.word_count = wordCount;
        }
        return { ...collectedSchoolWithEssay };
      });
      this.loadingWordCount = false;
    },
    async updateStudentEssaysWordCountAndStatus() {
      this.collectedSchoolWithEssays = this.collectedSchoolWithEssays
        .map(collectedSchoolWithEssay => {
          if (collectedSchoolWithEssay.essay.student_essay) {
            collectedSchoolWithEssay.essay.student_essay.status = Essay.getEssayStatus(
              collectedSchoolWithEssay.essay
            );
          }
          return { ...collectedSchoolWithEssay };
        });

      this.collectedSchoolWithEssaysCreated.forEach(collectedSchoolWithEssay => {
        essayApi.updateStudentEssay(collectedSchoolWithEssay.essay.student_essay.id, {
          word_count: collectedSchoolWithEssay.essay.student_essay.word_count,
          status: collectedSchoolWithEssay.essay.student_essay.status
        });
      });
    },
    async fetchUserEssayDocumentType() {
      const { student: { essay_doc_type } } = await profileApi.getUserData(this.studentId);
      return essay_doc_type;
    },
    async updateStudentEssayDocumentType() {
      if (this.isRoleAdmin() || this.isRoleTeacher()) {
        await this.$store.dispatch(
          "profile/updateStudentProfileByAdmin",
          { id: this.studentId, newProfile: { essay_doc_type: this.essayDocType }}
        );
      } else {
        await this.$store.dispatch("profile/updateStudentProfile", {
          essay_doc_type: this.essayDocType
        });
      }
      if (this.waittingForCreateEssayId) {
        await this.createApplicationEssayDocument(this.waittingForCreateEssayId);
        this.waittingForCreateEssayId = null;
      }
    },
    async createApplicationEssayDocument(essayPromptId) {
      this.essayDocType = await this.fetchUserEssayDocumentType();
      if (this.essayDocType === null) {
        this.waittingForCreateEssayId = essayPromptId;
        const GOOGLE = 1;
        this.essayDocType = GOOGLE;
        this.updateStudentEssayDocumentType();
        return;
      }
      const { student_essay: { essay_url } } = await essayApi.createApplicationEssayDocument(
        this.essayDocType, this.studentId, essayPromptId
      );
      window.open(essay_url);
      this.initialData();
    },
    toggleSchoolOpenStatus(index) {
      this.collectedCounselingSchools = this.collectedCounselingSchools
        .map((collectedCounselingSchool, collectedCounselingSchoolIndex) => {
          if (collectedCounselingSchoolIndex === index) {
            return {
              ...collectedCounselingSchool,
              opened: !collectedCounselingSchool.opened
            };
          } else {
            return { ...collectedCounselingSchool };
          }
        });
    },
    getSchoolEssayPromptsByName(schoolName) {
      return this.schoolsEssayPrompts.filter(({ name }) => name === schoolName);
    },
    expandAllSchools() {
      this.collectedCounselingSchools = this.collectedCounselingSchools.map((collectedCounselingSchool) => ({
        ...collectedCounselingSchool,
        opened: true
      }));
    },
    collapseAllSchools() {
      this.collectedCounselingSchools = this.collectedCounselingSchools.map((collectedCounselingSchool) => ({
        ...collectedCounselingSchool,
        opened: false
      }));
    },
    getAddCollegeObject() {
      if (this.isEnrolledCounseling) {
        return {
          name: "addCounselingSchool",
          params: {
            counselingId: this.sessionClass.id
          }
        };
      } else {
        return {
          name: "SchoolsList"
        };
      }
    }
  }
};
</script>

<style scoped>
.viewSelectedPromptBtns {
  margin-bottom: 16px;
}

.panelTitle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.panelContent {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  margin-top: 8px;
  padding: 0px 12px;
}

.panelTitle > span {
  font-size: 20px;
  margin: 0px 8px;
}
</style>
