import StudentEssayStatuses from "@/views/private/Counseling/EssayComponets/StudentEssayStatuses";

export default class Essay {
  static calculateEssayWordCount(docContent) {
    const paragraphs = docContent
      .filter(({ paragraph }) => paragraph)
      .map(({ paragraph }) => paragraph)
      .slice(3);
    let essayContent = "";
    for (const paragraph of paragraphs) {
      paragraph.elements.forEach((element) => {
        const { content } = element.textRun;
        essayContent += `${content} `;
      });
    }
    const words = essayContent.split(" ").filter(word => !["", "\n", "."].includes(word));
    return words.length;
  }

  static isEmptyEssay(essay) {
    return essay.student_essay && essay.student_essay.word_count === 0;;
  }

  static getEssayStatus(essay) {
    const isEmptyEssay = Essay.isEmptyEssay(essay);
    if (essay.student_essay) {
      if (isEmptyEssay) {
        return StudentEssayStatuses.incompleted;
      } else if (essay.student_essay.status !== 2 && !isEmptyEssay) {
        return StudentEssayStatuses.processing;
      } else {
        return essay.student_essay.status;
      }
    }
    return StudentEssayStatuses.incompleted;
  }
}
